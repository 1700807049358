.chart_img {
    height: 15px;
    margin-left: 10px;
    margin-right: 2px;
    background-color: white;
    border: 1px solid black;
}

.chart_img:hover {
    cursor: pointer;
}

.window {
    width: 100%;
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.75);
    left: 0;
    top: 0;
    position: fixed;
    z-index: 2;
}



.window_content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: row;
}

.plotly_wrapper {
    width: 100%;
}

.close::before {
    content: 'x'; 
    color: #fff;
    font-size: 3em;
    font-weight: 300;
    font-family: Arial, sans-serif;
}

.close:hover {
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
}

.close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 50px;
    height: 50px;
    text-align: center;
    vertical-align: auto;
}