.team_rating {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 40px;
    padding: 3px;
    box-shadow: 1px 1px 1px gray;
}

.away {
    background-color: #d15e5e;
}

.home {
    background-color: #6ecdea;
}

.team_rating span {
    text-align: center;
    /* height: 100%; */
}

.ratings_ratio {
    margin: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 80px;
    height: 100%;
    color: white;
    font-size: 0.9em;
}