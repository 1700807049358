.table_link:visited,
.table_link:active,
.table_link {
    color: black;
    text-decoration: underline;
}

.table_link:hover {
    text-decoration: none;
    cursor: pointer;
}
