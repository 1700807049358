.season_round_selector {
    display: flex;
    justify-content: center;
    font-size: 0.8em;
    margin: 3px;
    align-items: center;
}

.season_round_selector_link {
    justify-content: center;
    margin: 0 10px 0 10px;
    width: 10px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: -moz-none;
    -o-user-select: none;
    user-select: none;
}

.season_round_selector_link:hover {
    text-decoration: none;
    cursor: pointer;
}

.season_round_selector_link:active {
    transform: translate(1px, 1px);
}

.season_round_selector_center {
    display: flex;
    flex-direction: column;
}

.srselector_info {
    align-self: center;
}

.disabled {
    color: rgba(0, 0, 0, 0.3);
}
