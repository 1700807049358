.result_span {
    min-width: 30px;
    padding-left: 15px;
}

.home_team_span {
    background-color: #6ecdea;
}

.draw_span {
    background-color: #dce3de;
}

.away_team_span {
    background-color: #d15e5e;
}