
.language_links {
    /* align-self: flex-end; */
    font-size: 0.7em;
    margin-bottom: 0px;
}

.language_link_button {
    text-decoration: underline;
    color: black;

    border: none;

    background-color: transparent;
    border: none;
    cursor: pointer;

    margin: 0 3px -3px 0;
    padding: 0;
    text-align: left;
}

.language_link_button:hover {
    text-decoration: none;
    cursor: pointer;
}

.language_link_button:visited,
.language_link_button:active {
    color: black;
}

.credentials {
    position: relative;
    bottom: -15px;
    right: 0;
    text-align: right;
    font-size: 0.8em;
    color: #779dff;
}

.aside_link:visited,
.aside_link:active,
.aside_link {
    font-size: 15px;
    color: #426cd8;
    text-decoration: underline;
}

.aside_link:hover {
    text-decoration: none;
}

.hide_left_menu:hover {
    background-color: #9ca0ac42 !important;
}

.hide_left_menu:active {
    background-color: #dcdde0 !important;
}