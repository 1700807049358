.blur {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(220, 220, 220, 0.7);
    z-index: 100;
}

.blur_loader {
    position: absolute;
    left: 50%;
    transform: translateX(-50px);
    top: min(40%, 200px);
}

.blur_error {
    font-family: 'Open Sans', sans-serif;
    font-size: 1.1em;
    text-shadow: 0px 0.1px black;
}

.reload_img:hover {
    rotate: 45deg;
}
